// extracted by mini-css-extract-plugin
export var divider = "Index-module--divider--m52VG";
export var gridFull = "Index-module--grid-full--nGZrW";
export var gridImage = "Index-module--grid-image--5LiJ+";
export var gridLeft = "Index-module--grid-left--giAo4";
export var gridRight = "Index-module--grid-right--Ohb2J";
export var hero = "Index-module--hero--zouM1";
export var heroBig = "Index-module--hero-big--TofSg";
export var introBlock = "Index-module--intro-block--6GLK3";
export var items = "Index-module--items--g0VbM";
export var link = "Index-module--link--36AvR";
export var links = "Index-module--links--dKQTL";
export var mainGrid = "Index-module--main-grid--4JrVx";
export var mobileHead = "Index-module--mobile-head--aoaU1";
export var opening = "Index-module--opening--e2HUE";
export var portfolio = "Index-module--portfolio--L18Rx";
export var portfolioImg = "Index-module--portfolio-img--uXmKQ";
export var tag = "Index-module--tag--zEr4y";
export var themeDark = "Index-module--theme--dark--J9uIT";
export var themeDefault = "Index-module--theme--default--WuIiL";
export var titleBar = "Index-module--title-bar--IFUbJ";